<template>
  <div class="border-secondary">
    <div class="white">
      <div class="white--text black font-weight-medium d-flex align-center justify-space-between pa-2 pl-6">
        <span>{{ $t('providers.tariff_plan_setup') }}</span>
        <c-btn
          class="text-decoration-underline text-subtitle-1"
          text
          color="white"
          :label="$t('providers.cancel_subscription')"
          @click="unsubscribeDialogIsShown = true"
        />
      </div>
      <v-sheet max-width="500px" class="mx-auto pt-11 pb-14 mb-3">
        <div class="black--text  font-weight-600  text-center mb-2">
          {{ $t('providers.tariff_management') }}
        </div>
        <div class="black--text text-center">
          {{ $t('providers.tariff_description') }}
        </div>
      </v-sheet>
    </div>
    <providers-promo-plans :has-update="true" class="mt-8 mt-sm-9 pb-9" />
    <providers-promo-faq class="mt-16" />
    <unsubscribe-dialog
      :is-shown="unsubscribeDialogIsShown"
      :is-loading="isLoading"
      @confirm="deleteUserSubscription()"
      @close-dialog="unsubscribeDialogIsShown = false"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import ProvidersPromoPlans from '../ProvidersPromo/ProvidersPromoPlans/Index.vue'
  import ProvidersPromoFaq from '../ProvidersPromo/ProvidersPromoFaq.vue'
  import UnsubscribeDialog from '@/views/Providers/UnsubscribeDialog.vue'
  import { trafficProviderRepository } from '@/services/repository-factory.js'
  import routeNames from '@/types/route-names.js'
  import handleErrors from '@/services/handleErrors.js'

  export default {
    name: 'ProvidersSubscription',
    components: {
      CBtn,
      UnsubscribeDialog,
      ProvidersPromoPlans,
      ProvidersPromoFaq
    },
    data() {
      return {
        unsubscribeDialogIsShown: false,
        isLoading: false
      }
    },
    computed: {
      ...mapState('personal', ['trafficProviderSubscription']),
      ...mapGetters('personal', ['userHasProviderSubscription'])
    },
    async created() {
      if (!this.userHasProviderSubscription) {
        await this.$router.push({
          name: routeNames.PROVIDERS
        })
      }
    },
    methods: {
      ...mapActions('personal', ['fetchUserProfile']),
      async deleteUserSubscription() {
        this.isLoading = true
        const { id } = this.trafficProviderSubscription
        try {
          await trafficProviderRepository.delete(id)
          await this.$router.push({
            name: routeNames.PROVIDERS
          })
        } catch (error) {
          handleErrors(error)
        }
        await this.fetchUserProfile()
        this.isLoading = false
        this.unsubscribeDialogIsShown = false
      }
    }
  }
</script>

<style lang="scss" scoped>

.font-weight-600 {
  font-weight: 600;
}

</style>
